<template>
  <b-container fluid>
    <bo-page-title />
    <section id="home">
      <b-tabs>
        <b-tab title="Page Settings">
          <validation-observer v-slot="{ handleSubmit }" ref="VForm">
            <b-form @submit.prevent="handleSubmit(submitLegal)">
              <b-card no-body>
                <template #header>
                  <h5 class="card-title">{{row.ssc_page}}</h5>
                </template>
                <b-card-body>
                  <b-form-group label="Content (ID)" label-for="desc_id">
                    <CKEditor id="desc_id" :value.sync="row.ssc_desc_id" />
                    <VValidate name="Description Id" v-model="row.ssc_desc_id" :rules="mrValidation.ssc_desc_id" />
                  </b-form-group>

                  <b-form-group label="Content (EN)" label-for="desc_en">
                    <CKEditor id="desc_en" :value.sync="row.ssc_desc_en" />
                    <VValidate name="Description En" v-model="row.ssc_desc_en" :rules="mrValidation.ssc_desc_en" />
                  </b-form-group>
                </b-card-body>
                <b-card-footer>
                  <div class="text-right">
                    <b-button variant="primary" class="btn-rounded" type="submit">Save Changes</b-button>
                  </div>
                </b-card-footer>
              </b-card>
            </b-form>
          </validation-observer>
        </b-tab>

        <b-tab title="SEO Settings" v-if="moduleRole('updateSEO')">
          <b-card no-body>
            <b-card-header>
              <b-card-title title-tag="h5">{{row.ssc_page}} SEO Settings</b-card-title>
            </b-card-header>
            <validation-observer v-slot="{ handleSubmit }" ref="VFormSEO">
              <b-form @submit.prevent="handleSubmit(submitSEO)">
                <b-card-body>
                  <b-row>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Title (ID)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami judul dari halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Title adalah 60 karakter.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title"
                          v-model="seoPageSetting.sss_meta_title_id" />
                        <VValidate name="Meta Title ID" v-model="seoPageSetting.sss_meta_title_id"
                          :rules="{...seoValidation.sss_meta_title_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
                      </b-form-group>
                    </b-col>

                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTitle">
                        <template #label>
                          Meta Title (EN)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field functions to ease Search Engines understand the title of this website page. Pro tip: Make sure the characters length is less than 60 chars.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-input id="homeMetaTitle" placeholder="Meta Title"
                          v-model="seoPageSetting.sss_meta_title_en" />
                        <VValidate name="Meta Title EN" v-model="seoPageSetting.sss_meta_title_en"
                          :rules="{...seoValidation.sss_meta_title_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaDesc">
                        <template #label>
                          Meta Description (ID)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini sebagai deskripsi singkat dari isi halaman website. Tips: Maksimal karakter yang disarankan untuk Meta Description adalah 120-155 karakter.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description"
                          v-model="seoPageSetting.sss_meta_description_id" />
                        <VValidate name="Meta Description ID" v-model="seoPageSetting.sss_meta_description_id"
                          :rules="{...seoValidation.sss_meta_description_id, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
                      </b-form-group>
                    </b-col>

                    <b-col lg="6">
                      <b-form-group label-for="homeMetaDesc">
                        <template #label>
                          Meta Description (EN)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field acts as the summary for your website\'s page. A good practice is to keep it around 120-155 characters.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-textarea rows="5" id="homeMetaDesc" placeholder="Meta Description"
                          v-model="seoPageSetting.sss_meta_description_en" />
                        <VValidate name="Meta Description EN" v-model="seoPageSetting.sss_meta_description_en"
                          :rules="{...seoValidation.sss_meta_description_en, regex:/^[^\s]+(\s+[^\s]+)*$/}" />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTags">
                        <template #label>
                          Meta Keywords (ID)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'Isi kolom ini untuk memudahkan mesin pencari memahami topik halaman website. Gunakan keyword yang relevan dan user friendly, maksimal 10 keyword.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_id"
                          placeholder="Type and press enter ..." remove-on-delete tag-variant="primary"
                          :tag-validator="validatorTags"
                          tag-class="text-white" />
                      </b-form-group>
                    </b-col>

                    <b-col lg="6">
                      <b-form-group label-for="homeMetaTags">
                        <template #label>
                          Meta Keywords (EN)
                          <b-badge variant="info" pill class="help-badge"
                            v-b-tooltip.hover.right="'This field tell Search Engines what the topic of a page is. Use a relevant and short topic, and make sure to keep only up to 10 keywords.'">
                            <i class="fas fa-question"></i>
                          </b-badge>
                        </template>
                        <b-form-tags id="homeMetaTags" v-model="seoPageSetting.sss_meta_keyword_en"
                          placeholder="Type and press enter ..." remove-on-delete tag-variant="primary"
                          :tag-validator="validatorTags"
                          tag-class="text-white" />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
                <b-card-footer>
                  <div class="text-right">
                    <b-button class="btn-rounded" type="submit" variant="primary">Save Changes</b-button>
                  </div>
                </b-card-footer>
              </b-form>
            </validation-observer>
          </b-card>
        </b-tab>
      </b-tabs>
    </section>
  </b-container>
</template>
<script>
import GlobalVue from '@/libs/Global.vue'
import CKEditor from '@/components/CKEditor'

let $ = global.jQuery
let _ = global._

export default {
  extends: GlobalVue,
  components: {
    CKEditor
  },
  data() {
    return {
      mrValidation: {},
      itemsToolbar: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'undo',
        'redo'
      ],
      seoPageSetting: {},
      seoValidation: {},
    }
  },
  mounted(){
    this.apiGet()
  },
  methods: {
    submitLegal(){
      this.doSubmit(
        "/do/" + this.modulePage,
        _.assign({
          type: 'update'
        }, this.row),
        (type, resp) => {
          if (type == 'success') {
            this.loadingOverlay = true
            this.apiGet()
            window.scrollTo(0, 0)
          }else{
            if(resp.response.status==422) {
              if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                var msg = ""
                $.each(Object.keys(resp.response.data), (i, v) => {
                  msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v])
                })
                return this.$swal.fire("Blocked With Validation!", msg)
              } else {
                return this.$swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
              }
            } else if (resp.response.status == 400) {
              return this.$swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        },
        'POST', 'VForm'
      )
    }
  },
  watch: {
    $route() {
      this.apiGet()
    },
        // counter seo
    'seoPageSetting.sss_meta_title_id'(v){
      let el = document.getElementById('Meta Title ID')
      if(el){
        let showcount = document.getElementById('Meta Title ID' + 'count')
        let cErr = document.getElementById('Meta Title ID' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          el.style.display = "none"
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"        
        }
      }
    },
    'seoPageSetting.sss_meta_title_en'(v){
      let el = document.getElementById('Meta Title EN')
      if(el){
        let showcount = document.getElementById('Meta Title EN' + 'count')
        let cErr = document.getElementById('Meta Title EN' + 'count-err')
        if(v.length >= 3 && v.length < 60){
          showcount.innerHTML = 60 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"          
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_id'(v){
      let el = document.getElementById('Meta Description ID')
      if(el){
        let showcount = document.getElementById('Meta Description ID' + 'count')
        let cErr = document.getElementById('Meta Description ID' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
    'seoPageSetting.sss_meta_description_en'(v){
      let el = document.getElementById('Meta Description EN')
      if(el){
        let showcount = document.getElementById('Meta Description EN' + 'count')
        let cErr = document.getElementById('Meta Description EN' + 'count-err')
        if(v.length >= 3 && v.length < 160){
          showcount.innerHTML = 160 - v.length + " characters remaining"
          showcount.style.display = "block"
          cErr.style.display = "none"
        }
        else if(v.length < 4){
          cErr.style.display = "none"     
          showcount.style.display = "none"        
        }
        else{
          cErr.style.display = "block"
          showcount.style.display = "none"
        }
      }
    },
  }
}
</script>